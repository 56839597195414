import '../styles/global.scss';
import '../styles/pages/cancel.scss';
import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import {HeadFC} from "gatsby";
import {
    CrownImage,
    LoadingGif,
    LogoImage,
} from "../images";
import {useMemo, useState} from "react";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import {toast, ToastContainer} from "react-toastify";

const PaymentPage = () => {

    // const [eventStatus, setEventStatus] = useState(false);
    // useEffect( () => {
    //     if(!eventStatus){
    //         fbq("track", "Lead");
    //         console.log('fb track lead');
    //         setEventStatus(true);
    //     }
    // }, [eventStatus]);

    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [username, setUserName] = useState('');
    const [card, setCard] = useState('');
    const [reason, setReason] = useState('');
    const [isError, setIsError] = useState(false);

    const validateForm = () => {
        let errorCounter = 0;

        (email.length !== 0 && email.includes('@')) ? errorCounter : errorCounter += 1;
        (username.length !== 0) ? errorCounter : errorCounter += 1;
        (card.length === 4) ? errorCounter : errorCounter += 1;
        (reason.length !== 0) ? errorCounter : errorCounter += 1;

        if(errorCounter === 0){
            const messageArray = {
                'Email': email,
                'Username': username,
                'Card': card,
                'Reason': reason,
            };
            let message = '';
            Object.keys(messageArray).map(key => {
                message += '<b>' + key + ':</b> ' + messageArray[key] + '%0A';
            });

            const token = "917750430:AAE077UFH-9-4qj8Y3tdug4TRaWvJIFeBL4";
            const chatId = "-1001681538921";
            const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${message}`;
            sendForm(url);
        } else{
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
        }
    }
    
    const sendForm = (fetchUrl) => {
        fetch(fetchUrl)
        .then(res => res.json())
        .then(
            (result) => {
                setEmail('');
                setUserName('');
                setCard('');
                setReason('');
            },
            (error) => {
                console.log('Error');
            }
        );
    }

    return (
        <div>
            <div className={`emailError ${isError ? 'active' : ''}`}>Please fill in all fields</div>
            <div className="paymentWrap">
                <div className="paymentContainer">
                    <div className="paymentContainerTop">
                        <div className="paymentLogo">
                            <img src={LogoImage} width={150} alt=""/>
                        </div>
                        <div className="emailNote">
                            Thanks you! <br />
                            We will contact you as soon as posible
                        </div>
                    </div>
                </div>
            </div>
            {isLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}
            <ToastContainer/>
        </div>
    )
}

export default PaymentPage;

export const Head: HeadFC = () => (
    <>
        <title>Cancel subscription - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);

